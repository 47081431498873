<template>
  <div style="height: calc(100% - 40px) !important; position: relative">
    <canvas :id="_uid"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
export default {
  name: 'BarChart',
  data() {
    return {
      chart: null
    };
  },
  props: {
    item: Object
  },
  mounted() {
    this.chart = new Chart(document.getElementById(this._uid), {
      type: 'bar',
      data: {
        labels: ['01/16', '02/16', '03/16', '04/16', '05/16'],
        datasets: [
          {
            label: 'Faturamento',
            backgroundColor: ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850'],
            data: [52478, 55267, 84000, 93000, 87000]
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 5,
            bottom: 15
          }
        },
        legend: { display: false }
      }
    });
  }
};
</script>

<style></style>
