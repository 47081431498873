<template>
  <div style="height: calc(100% - 50px) !important; position: relative">
    <canvas :id="_uid"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
export default {
  name: 'LineChart',
  data() {
    return {
      chart: null
    };
  },
  props: {
    item: Object
  },
  mounted() {
    this.chart = new Chart(document.getElementById(this._uid), {
      type: 'line',
      data: {
        labels: ['2008', '2009', '2010', '2011', '2012', '2013', '2014'],
        datasets: [
          {
            data: [86, 114, 106, 106, 107, 111, 133],
            label: 'Total',
            borderColor: '#3e95cd',
            backgroundColor: '#7bb6dd',
            fill: false
          },
          {
            data: [70, 90, 44, 60, 83, 90, 100],
            label: 'Accepted',
            borderColor: '#3cba9f',
            backgroundColor: '#71d1bd',
            fill: false
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 5,
            bottom: 15
          }
        },
        legend: { display: true }
      }
    });
  }
};
</script>

<style></style>
