<template>
  <div>
    <q-intersection transition="slide-down" v-show="filter">
      <q-form @submit.prevent="onFilterReports" @reset="clearFilter">
        <q-card flat v-if="filter" bordered class="q-mb-sm">
          <q-toolbar>
            <q-toolbar-title> {{ $t('filter') }} </q-toolbar-title>
            <q-btn flat round icon="mdi-close" @click="filter = false" />
          </q-toolbar>
          <q-card-section class="q-pt-none row q-col-gutter-md">
            <div class="col-4">
              <date-input
                buttons
                outlined
                label="Data dos cliques"
                :v-model="params.date"
                @update="(value) => (params.date = value)"
                clearable
                range
                :rules="[(val) => !!val || 'O campo data dos cliques é obrigatório']"
              />
            </div>
            <div class="col-4">
              <q-select
                v-model="params.displayMode"
                :options="[
                  {
                    label: 'Por campanha',
                    value: 'campaign'
                  },
                  {
                    label: 'Por afiliado',
                    value: 'affiliate'
                  },
                  {
                    label: 'Por canal',
                    value: 'site'
                  },
                  {
                    label: 'Por dia',
                    value: 'day'
                  },
                  {
                    label: 'Por mês',
                    value: 'month'
                  },
                  {
                    label: 'Por ano',
                    value: 'year'
                  }
                ]"
                label="Modo de exibição"
                :rules="[(val) => !!val || 'O campo de modo de exibição é obrigatório.']"
                outlined
              />
            </div>

            <div class="col-4">
              <q-select
                v-model="params.campaign"
                use-input
                input-debounce="200"
                @filter="filterComboCampaigns"
                :options="campaignsFiltered"
                label="Campanha"
                option-label="name"
                option-value="id"
                outlined
              />
            </div>

            <div class="col-4">
              <q-input v-model="params.siteId" use-input input-debounce="200" label="Canal ID" outlined type="number" />
            </div>

            <div class="col-4">
              <q-input v-model="params.siteName" use-input label="Nome do canal" outlined />
            </div>

            <div class="col-4">
              <q-input v-model="params.affiliateName" use-input label="Nome do Afiliado" outlined />
            </div>

            <div class="col-12 text-right">
              <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" type="reset" />
              <!-- @click="clearFilter" -->
              <!-- to="advertiser/reports/click" -->
              <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" type="submit" />
            </div>
          </q-card-section>
        </q-card>
      </q-form>
    </q-intersection>
  </div>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';
import DateInput from '../../../widgets/inputsGlobal/DateInput';

export default {
  name: 'AdvertiserHeaderClickReports',
  mixins: [ReportMixin],
  components: { DateInput },

  data() {
    return {
      filter: true,
      params: {
        campaign: null,
        displayMode: null,
        date: null,
        siteId: null,
        siteName: null,
        affiliateName: null
      },
      campaignsList: [],
      campaignsFiltered: []
    };
  },
  /*   watch: {
      search() {
        this.ActionSetFilterReports(this.search);
      },
    }, */

  created() {
    this.getCampaigns();
  },

  methods: {
    async getCampaigns() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=advertiser&path=/api/get/campaign/advertiser/' + this.user.advertiser_id
        );
        if (status === 200) {
          this.campaignsList = data;
          this.campaignsFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterComboCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignsFiltered = this.campaignsList;
        this.campaignsFiltered = this.campaignsList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    clearFilter() {
      for (let i in this.params) this.params[i] = Array.isArray(this.params[i]) ? [] : null;

      this.ActionSetReports([]);
      this.ActionSetFilterReports();
      //this.getClickReportAdvertiser();
    },

    onFilterReports() {
      let filterSelect = [];

      filterSelect.push(`id_advertiser=${this.user.advertiser_id}`);
      //filterSelect.push(`id_affiliate=21`);

      if (this.params.campaign) filterSelect.push(`id_campaign=${this.params.campaign.id}`);

      if (this.params.siteId) filterSelect.push(`one_site=${this.params.siteId}`);

      if (this.params.siteName) filterSelect.push(`site_name=${this.params.siteName}`);

      if (this.params.affiliateName) filterSelect.push(`affiliate_name=${this.params.affiliateName}`);

      if (this.params.date) {
        let [from, , to] = this.params.date.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        filterSelect.push(`start_date=${from}`);
        filterSelect.push(`end_date=${to}`);
      }

      if (this.params.displayMode) filterSelect.push(`filter=${this.params.displayMode.value}`);

      this.ActionSetFilterReports(filterSelect.join('%26'));
      this.getClickReportAdvertiser();
      //this.getClickReportAffiliate();
    }
  }
};
</script>
