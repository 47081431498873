<template>
  <div style="height: calc(100% - 50px) !important; position: relative">
    <canvas :id="_uid"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
export default {
  name: 'DoughnutChart',
  data() {
    return {
      chart: null
    };
  },
  props: {
    item: Object
  },
  mounted() {
    this.chart = new Chart(document.getElementById(this._uid), {
      type: 'doughnut',
      data: {
        labels: ['Accepted', 'Pending', 'Rejected'],
        datasets: [
          {
            data: [70, 10, 6],
            backgroundColor: ['#3cba9f', '#ffa500', '#c45850'],
            borderWidth: 2
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 5,
            bottom: 15
          }
        },
        legend: { display: true }
      }
    });
  }
};
</script>

<style></style>
