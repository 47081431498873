<template>
  <q-carousel
    animated
    v-model="slide"
    arrows
    navigation
    infinite
    control-color="white"
    control-text-color="primary"
    height="100%"
    class="text-primary"
  >
    <q-carousel-slide :name="1">
      <q-img class="full-height" contain :src="require('@/assets/images/banner_1.png')" />
    </q-carousel-slide>
    <q-carousel-slide :name="2" @click="onClickBanner2()">
      <q-img class="full-height cursor-pointer" contain :src="require('@/assets/images/banner_2.jpg')" />
    </q-carousel-slide>
    <q-carousel-slide :name="3" @click="onClickBanner3()">
      <q-img class="full-height cursor-pointer" contain :src="require('@/assets/images/banner_3.jpg')" />
    </q-carousel-slide>
    <q-carousel-slide :name="4">
      <q-img class="full-height" contain :src="require('@/assets/images/banner_5.png')" />
    </q-carousel-slide>
    <q-carousel-slide v-if="isVisible" :name="5">
      <q-img class="full-height" contain :src="require('@/assets/images/banner_betconnect.jpg')">
        <div class="absolute-bottom q-gutter-x-xl q-mb-lg row justify-center bg-transparent">
          <q-btn class="q-px-sm" color="secondary" no-caps dense label="Lista de campanhas" to="/mycampaigns/bet" />
          <q-btn
            class="q-px-sm q-mr-xl"
            color="white"
            text-color="secondary"
            no-caps
            dense
            label="Para mais informações"
            href="http://betconnect.com.br/"
            target="_blank"
          />
        </div>
      </q-img>
    </q-carousel-slide>
  </q-carousel>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      slide: 1,
      isVisible: false
    };
  },

  mounted() {
    const currentDate = new Date();
    const targetDate = new Date('2024-11-13');

    if (currentDate <= targetDate) {
      this.isVisible = true;
      this.slide = 5;
    }
  },

  methods: {
    onClickBanner2() {
      window.open(
        'https://afilio-public-general.s3.amazonaws.com/2022/institucional/manuais/manual_plataforma_v3.pdf',
        '_blank'
      );
    },
    onClickBanner3() {
      window.open('https://api.whatsapp.com/send?phone=5511996935088', '_blank');
    }
  }
};
</script>
