<template>
  <q-table
    flat
    dense
    :data="data"
    :columns="columns"
    row-key="id"
    responsive
    style="height: calc(100% - 50px) !important; position: relative"
  >
    <template v-slot:body-cell-name="props">
      <q-td :props="props">
        <q-item class="q-pa-xs">
          <q-item-section top avatar>
            <q-avatar color="primary" text-color="white" icon="mdi-account" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Single line item</q-item-label>
            <q-item-label caption lines="2">Secondary line text.</q-item-label>
          </q-item-section>
        </q-item>
      </q-td>
    </template>
  </q-table>
</template>

<script>
import { QSpinnerFacebook } from 'quasar';
export default {
  data() {
    return {
      grid: false,
      selected: [],
      filter: '',
      time: null,
      columns: [
        {
          name: 'name',
          required: true,
          label: 'Name',
          align: 'left',
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true
        },
        {
          name: 'phone',
          label: 'Phone',
          field: 'phone',
          sortable: true,
          align: 'left'
        },
        {
          name: 'location',
          label: 'Location',
          field: 'location',
          sortable: true,
          align: 'left'
        },
        { name: 'Date', label: 'Date', field: 'date', align: 'left' }
        // {
        //   name: "id",
        //   label: "Ações",
        //   field: "id",
        //   sortable: true,
        // },
      ],
      data: [
        {
          name: 'Frozen Yogurt',
          phone: 159,
          location: 6.0,
          date: '07/08/2021',
          id: 1
        },
        {
          name: 'Ice cream sandwich',
          phone: 237,
          location: 9.0,
          date: '07/08/2021',
          id: 2
        },
        {
          name: 'Eclair',
          phone: 262,
          location: 16.0,
          date: '07/08/2021',
          id: 3
        },
        {
          name: 'Cupcake',
          phone: 305,
          location: 3.7,
          date: '07/08/2021',
          id: 4
        },
        {
          name: 'Gingerbread',
          phone: 356,
          location: 16.0,
          date: '07/08/2021',
          id: 5
        }
      ]
    };
  },
  methods: {
    async getAdvertiser() {
      this.showLoading();
      try {
        const { data } = await this.$http.get('redirect?app=ADVERTISER&path=/api/get/campaign/1');
        console.log(data);
      } catch (error) {
        console.log(error);
      } finally {
        this.timer = setTimeout(() => {
          this.$q.loading.hide();
          this.timer = void 0;
        }, 1000);
      }
    },
    showLoading() {
      this.$q.loading.show({
        spinner: QSpinnerFacebook,
        spinnerColor: 'primary',
        spinnerSize: 140,
        backgroundColor: 'primary',
        message: 'Carregando...',
        messageColor: 'white'
      });
    }
  }
};
</script>

<style></style>
