<template>
  <q-card flat class="scroll">
    <q-card-section class="q-py-none">
      <!-- <AdvertiserHeaderConversionReports /> -->
      <AdvertiserListConversionReports />
    </q-card-section>
  </q-card>
</template>

<script>
// import AdvertiserHeaderConversionReports from "@/components/reports/advertiser/AdvertiserHeaderConversionReports";
import AdvertiserListConversionReports from '@/components/reports/advertiser/AdvertiserListConversionReports';

export default {
  name: 'CardReportConversionAdvertiser',
  components: {
    // AdvertiserHeaderConversionReports,
    AdvertiserListConversionReports
  }
};
</script>
