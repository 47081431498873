<template>
  <div style="height: calc(100% - 50px) !important; position: relative">
    <canvas :id="_uid"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
export default {
  name: 'RadarChart',
  data() {
    return {
      chart: null
    };
  },
  props: {
    item: Object
  },
  mounted() {
    this.chart = new Chart(document.getElementById(this._uid), {
      type: 'radar',
      data: {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
          {
            data: [86, 114, 106, 106, 107, 111, 133],
            label: 'Total',
            borderColor: '#3e95cd',
            backgroundColor: '#7bb6dd',
            fill: false
          },
          {
            data: [70, 90, 44, 60, 83, 90, 100],
            label: 'Accepted',
            borderColor: '#3cba9f',
            backgroundColor: '#71d1bd',
            fill: false
          },
          {
            data: [10, 21, 60, 44, 17, 21, 17],
            label: 'Pending',
            borderColor: '#ffa500',
            backgroundColor: '#ffc04d',
            fill: false
          },
          {
            data: [6, 3, 2, 2, 7, 0, 16],
            label: 'Rejected',
            borderColor: '#c45850',
            backgroundColor: '#d78f89',
            fill: false
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 5,
            bottom: 15
          }
        },
        legend: { display: true }
      }
    });
  }
};
</script>

<style></style>
