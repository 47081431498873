<template>
  <q-page padding>
    <q-toolbar class="q-px-md">
      <q-toolbar-title class="text-weight-medium"> Página Inicial </q-toolbar-title>
      <q-toggle v-model="edit" :label="$t('edit')" />
    </q-toolbar>
    <dashboard-main :editGrid="edit" />
  </q-page>
</template>

<style></style>

<script>
import DashboardMain from '@/components/dashboard/DashboardMain.vue';

export default {
  components: { DashboardMain },
  name: 'PageDashboard',
  data() {
    return {
      edit: false
    };
  }
};
</script>
