<template>
  <div>
    <!-- {{currentPage}}
    {{reports}} -->
    <q-table
      flat
      bordered
      :data="reports"
      row-key="id"
      :columns="conversionColumns"
      :pagination="pagination"
      :visible-columns="visibleColumns"
    >
      <template v-slot:top-right>
        <q-btn
          outline
          color="grey-3"
          size="12px"
          text-color="grey-8"
          icon-right="archive"
          class="q-mr-sm q-py-xs"
          no-caps
          @click="exportClickReport()"
          unelevated
        >
          <q-tooltip> Exportar como CSV </q-tooltip>
        </q-btn>
        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          :display-value="$t('customize_columns')"
          emit-value
          map-options
          :options="conversionColumns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        >
          <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </template>
      <template v-slot:bottom>
        <!-- <pagination-table-reports
        @change="getConversionReport"
        :data="reqPagination"
        :pagination="pagination"
      /> -->
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          Total:{{ reqPagination.total }}
          <q-space />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
  </div>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';
export default {
  name: 'AdvertiserListConversionReports',
  mixins: [ReportMixin],
  data() {
    return {
      visibleColumns: null,
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  computed: {
    conversionColumns() {
      return [
        {
          name: 'campaign_id',
          label: 'ID Campanha',
          align: 'left',
          field: 'campaign_id',
          sortable: true,
          order: '0'
        },
        {
          name: 'campaign_name',
          label: 'Campanha',
          align: 'left',
          field: 'campaign_name',
          sortable: true,
          order: '1'
        },
        {
          name: 'advertiser_id',
          label: 'ID Advertiser',
          align: 'left',
          field: 'advertiser_id',
          sortable: true,
          order: '2'
        },
        {
          name: 'site_name',
          label: 'Canal',
          align: 'left',
          field: 'site_name',
          sortable: true,
          order: '3'
        },
        {
          name: 'site_id',
          label: 'ID Canal',
          align: 'left',
          field: 'site_id',
          sortable: true
        },
        {
          name: 'affiliate_name',
          label: 'Nome Afiliado',
          align: 'left',
          field: 'affiliate_name',
          sortable: true,
          order: '4'
        },
        {
          name: 'transaction_id',
          label: 'ID Conversão',
          align: 'left',
          field: 'transaction_id',
          sortable: true,
          order: '5'
        },
        {
          name: 'order_id',
          label: 'Tra ID',
          align: 'left',
          field: 'order_id',
          sortable: true,
          order: '6'
        },
        {
          name: 'creative_id',
          label: 'ID Criativo',
          align: 'left',
          field: 'creative_id',
          sortable: true,
          order: '7'
        },
        {
          name: 'comission_type',
          label: 'Tipo',
          align: 'left',
          field: 'comission_type',
          sortable: true,
          format: (text) => text.toUpperCase(),
          order: '8'
        },
        {
          name: 'transaction_amount',
          label: 'Valor Conversão',
          align: 'left',
          field: 'transaction_amount',
          sortable: true,
          order: '9'
        },
        {
          name: 'full_commission_amount',
          label: 'Comissão Full',
          align: 'left',
          field: 'full_commission_amount',
          sortable: true,
          order: '10'
        },
        {
          name: 'ad_xtra1',
          label: 'Parâmetro Xtra1',
          align: 'left',
          field: 'ad_xtra1',
          sortable: true,
          order: '11'
        },
        {
          name: 'ad_xtra2',
          label: 'Parâmetro Xtra2',
          align: 'left',
          field: 'ad_xtra2',
          sortable: true,
          order: '12'
        },
        {
          name: 'ad_xtra3',
          label: 'Parâmetro Xtra3',
          align: 'left',
          field: 'ad_xtra3',
          sortable: true,
          order: '13'
        },
        {
          name: 'click_datetime',
          label: this.$t('click_datetime'),
          align: 'left',
          field: 'click_datetime',
          sortable: true,
          order: '19'
        },
        {
          name: 'transaction_datetime',
          label: this.$t('transaction_datetime'),
          align: 'left',
          field: 'transaction_datetime',
          sortable: true,
          order: '20'
        },
        {
          name: 'validation_datetime',
          label: this.$t('validation_datetime'),
          align: 'left',
          field: 'validation_datetime',
          sortable: true,
          order: '21'
        },
        {
          name: 'validation_status',
          label: 'Status',
          align: 'left',
          field: 'validation_status',
          sortable: true,
          format: (status) => this.labelStatus(status),
          order: '22'
        },
        {
          name: 'source_url',
          label: 'URL Resp.',
          align: 'left',
          field: 'source_url',
          sortable: true,
          order: '23'
        }
      ];
    },

    conversionColumnsCsv() {
      return [
        ...this.conversionColumns,
        {
          name: 'ad_xtra4',
          label: 'Parâmetro Xtra4',
          align: 'left',
          field: 'ad_xtra4',
          sortable: true,
          order: '14'
        },
        {
          name: 'ad_xtra5',
          label: 'Parâmetro Xtra5',
          align: 'left',
          field: 'ad_xtra5',
          sortable: true,
          order: '15'
        },
        {
          name: 'ad_xtra6',
          label: 'Parâmetro Xtra6',
          align: 'left',
          field: 'ad_xtra6',
          sortable: true,
          order: '16'
        },
        {
          name: 'ad_xtra7',
          label: 'Parâmetro Xtra7',
          align: 'left',
          field: 'ad_xtra7',
          sortable: true,
          order: '17'
        }
      ];
    },

    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getConversionReportAdvertiser(`%26page=${this.currentPage}`, `%26per_page=${this.pagination.rowsPerPage}`);
      }
    }
  },
  methods: {
    async exportClickReport() {
      console.log(this.filterReports);
      this.onLoading(true);
      let filter;
      if (this.filterReports) filter = this.filterReports.replace('id_affiliate', 'affiliate_id');
      try {
        const { data, status } = await this.$http.get(
          'redirect?app=report&path=/api/old/report/advertiser_transaction_report_csv?' + filter
        );
        if (status === 200 || status === 201) {
          this.exportTableCsv(
            data,
            //this.conversionColumnsCsv.sort((x, y) => x.order == y.order ? 0 : x.order > y.order ? 1 : -1),
            this.conversionColumnsCsv.sort((x, y) => x.order - y.order),
            'conversion_reports'
          );
          this.successNotify('Exportado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    labelStatus(status) {
      switch (status) {
        case 0:
          return 'Recusado';
        case 1:
          return 'Pendente';
        case 2:
          return 'Aprovado';
        case 3:
          return 'Faturado';
        case 4:
          return 'Pago';
        default:
          return '';
      }
    }
  },
  created() {
    this.ActionSetFilterReports([`id_advertiser=${this.user.advertiser_id}`].join('%26'));
    this.ActionSetReports();
    this.visibleColumns = this.conversionColumns.map((column) => column.name);
    this.getConversionReportAdvertiser(null, '%26per_page=' + this.pagination.rowsPerPage);
  }
};
</script>
