<template>
  <q-item>
    <q-item-section>
      <q-item-label class="text-h4"
        >{{ data.extras.fullValue }}
        <!-- <q-icon
          v-if="data.extras.indicator === 'positive'"
          name="mdi-arrow-up"
          color="positive"
          size="sm"
        />
        <q-icon v-else name="mdi-arrow-down" color="negative" size="sm" /> -->
      </q-item-label>
      <q-item-label lines="2" v-if="data.extras.value"
        ><span class="text-weight-bold">{{ data.extras.label }}:</span> {{ data.extras.value }}</q-item-label
      >
    </q-item-section>
    <q-item-section avatar>
      <q-icon :name="data.extras.icon" color="grey-3" size="5rem" />
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: 'SigleCard',
  props: {
    data: Object
  }
};
</script>

<style></style>
