<template>
  <grid-layout
    :layout.sync="layoutFilter"
    :col-num="12"
    :row-height="30"
    :is-draggable="editGrid"
    :is-resizable="editGrid"
    :vertical-compact="true"
    :responsive="true"
    :margin="[15, 15]"
    :use-css-transforms="true"
    :cols="{ lg: 12, md: 12, sm: 1, xs: 1, xxs: 1 }"
  >
    <grid-item v-for="item in layoutFilter" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i">
      <q-card class="fit" bordered flat>
        <q-toolbar class="q-pr-xs" v-if="item.title">
          <q-toolbar-title class="text-subtitle1 text-weight-medium">
            {{ $t(item.title) }}
          </q-toolbar-title>
          <q-btn flat round dense icon="more_vert" />
        </q-toolbar>
        <component :is="item.component" :data="item" />
      </q-card>
    </grid-item>
  </grid-layout>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout';
import BarChart from '@/components/dashboard/charts/BarChart.vue';
import LineChart from '@/components/dashboard/charts/LineChart.vue';
import RadarChart from '@/components/dashboard/charts/RadarChart.vue';
import DoughnutChart from '@/components/dashboard/charts/DoughnutChart.vue';
import SingleCard from '@/components/dashboard/cards/SingleCard.vue';
import SlideShow from '@/components/dashboard/cards/SlideShow.vue';
import ListUsers from '@/components/dashboard/lists/ListUsers.vue';
import ListDailyPerformance from '@/components/dashboard/lists/ListDailyPerformance.vue';
import GeneratorDeeplink from '../../components/deeplink/GeneratorDeeplink';
import CardReportClickAdvertiser from '@/components/dashboard/cards/CardReportClickAdvertiser.vue';
import CardReportConversionAdvertiser from '@/components/dashboard/cards/CardReportConversionAdvertiser.vue';
import DashboardResume from '../../services/DashboardResume';

export default {
  name: 'DashboardMain',
  mixins: [DashboardResume],
  components: {
    GridLayout,
    CardReportClickAdvertiser,
    CardReportConversionAdvertiser,
    GridItem,
    BarChart,
    LineChart,
    RadarChart,
    DoughnutChart,
    SingleCard,
    ListUsers,
    SlideShow,
    ListDailyPerformance,
    GeneratorDeeplink
  },
  props: {
    editGrid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layout: [
        {
          role: 'admin',
          x: 0,
          y: 0,
          w: 4,
          h: 4,
          i: '0',
          title: 'Comissão Full',
          extras: {
            fullValue: '',
            icon: 'mdi-brightness-percent',
            indicator: 'positive'
          },
          component: 'SingleCard'
        },
        {
          role: 'admin',
          x: 4,
          y: 0,
          w: 4,
          h: 4,
          i: '1',
          title: 'Margem',
          extras: {
            fullValue: '',
            icon: 'mdi-chart-areaspline',
            indicator: 'negative'
          },
          component: 'SingleCard'
        },
        {
          role: 'admin',
          x: 8,
          y: 0,
          w: 4,
          h: 4,
          i: '2',
          title: 'Meta do dia',
          extras: {
            fullValue: '',
            icon: 'mdi-calendar',
            indicator: 'positive'
          },
          component: 'SingleCard'
        },
        {
          role: 'admin',
          x: 0,
          y: 4,
          w: 4,
          h: 4,
          i: '3',
          title: 'Expectativa da meta',
          extras: {
            fullValue: '',
            icon: 'mdi-finance',
            indicator: 'negative'
          },
          component: 'SingleCard'
        },
        {
          role: 'admin',
          x: 4,
          y: 4,
          w: 4,
          h: 4,
          i: '4',
          title: 'Meta do mês',
          extras: {
            fullValue: '',
            icon: 'mdi-calendar-month',
            indicator: 'negative'
          },
          component: 'SingleCard'
        },
        {
          role: 'admin',
          x: 8,
          y: 4,
          w: 4,
          h: 4,
          i: '5',
          title: 'Margem acumulada com taxa de validação',
          extras: {
            fullValue: '',
            label: '',
            value: '',
            icon: 'mdi-margin',
            indicator: 'positive'
          },
          component: 'SingleCard'
        },
        {
          role: 'afiliado',
          x: 0,
          y: 0,
          w: 8,
          h: 8,
          i: '11',
          title: '',
          component: 'SlideShow'
        },
        {
          role: 'afiliado',
          x: 8,
          y: 0,
          w: 4,
          h: 8,
          i: '12',
          title: 'generate_deeplink',
          component: 'GeneratorDeeplink'
        },
        {
          role: 'afiliado',
          x: 0,
          y: 10,
          w: 12,
          h: 7,
          i: '13',
          title: 'daily_performance',
          extras: {},
          component: 'ListDailyPerformance'
        },
        // {
        //     role: "anunciante",
        //     x: 6,
        //     y: 0,
        //     w: 6,
        //     h: 10,
        //     i: "15",
        //     title: "Relatório de Cliques",
        //     component: "CardReportClickAdvertiser",
        // },
        {
          role: 'anunciante',
          x: 0,
          y: 0,
          w: 12,
          h: 17,
          i: '15',
          title: 'Relatório de Conversões',
          component: 'CardReportConversionAdvertiser'
        }
      ],
      layoutFilter: []
    };
  },
  //   computed: {
  //     layoutFilter() {
  //       if (this.user.profile === "afiliado")
  //         return this.layout.filter((item) => item.role === "afiliado");
  //       else if (this.user.profile == "anunciante")
  //         return this.layout.filter((item) => item.role === "anunciante");
  //       else
  //         return this.layout.filter(
  //           (item) => item.role !== "afiliado" && item.role !== "anunciante"
  //         );
  //     },
  //   },
  methods: {
    onResize(size) {
      this.size = size;
    },
    formatDecimal(value) {
      return `R$ ${value
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    },
    async listPerformanceResume(date) {
      if (this.user.profile == 'anunciante' || this.user.profile == 'afiliado') return;
      this.onLoading(true);
      try {
        const { data, status } = await this.getPerformanceResume(date);
        console.log('relatorio:', data);
        if (status === 200) {
          this.layoutFilter[0].extras.fullValue =
            data.performance_resume.commission_full !== 0
              ? this.formatDecimal(data.performance_resume.commission_full)
              : 'Indefinido';
          this.layoutFilter[1].extras.fullValue =
            data.performance_resume.margin !== 0 ? this.formatDecimal(data.performance_resume.margin) : 'Indefinido';
          this.layoutFilter[2].extras.fullValue =
            data.performance_resume.daily_goal !== 0
              ? this.formatDecimal(data.performance_resume.daily_goal)
              : 'Indefinido';
          this.layoutFilter[3].extras.fullValue =
            data.performance_resume.expectation_goal !== 0
              ? this.formatDecimal(data.performance_resume.expectation_goal)
              : 'Indefinido';
          this.layoutFilter[4].extras.fullValue =
            data.performance_resume.month_goal !== 0
              ? this.formatDecimal(data.performance_resume.month_goal)
              : 'Indefinido';
          this.layoutFilter[5].extras.fullValue =
            data.performance_resume.full_margin_with_validate !== 0
              ? this.formatDecimal(data.performance_resume.full_margin_with_validate)
              : 'Indefinido';
          this.layoutFilter[5].extras.value =
            data.performance_resume.full_margin_with_validate_last_month !== 0
              ? this.formatDecimal(data.performance_resume.full_margin_with_validate_last_month)
              : 'Indefinido';
          this.layoutFilter[5].extras.label = 'Mês Anterior';
        }
      } finally {
        this.onLoading(false);
      }
    },
    getLayoutProfile() {
      if (this.user.profile === 'afiliado') this.layoutFilter = this.layout.filter((item) => item.role === 'afiliado');
      else if (this.user.profile == 'anunciante')
        this.layoutFilter = this.layout.filter((item) => item.role === 'anunciante');
      else this.layoutFilter = this.layout.filter((item) => item.role !== 'afiliado' && item.role !== 'anunciante');
    }
  },
  created() {
    this.getLayoutProfile();
  },
  mounted() {
    this.listPerformanceResume(null);
  }
};
</script>

<style></style>
