<template>
  <div>
    <AdvertiserHeaderClickReports />
    <AdvertiserListClickReports />
  </div>
</template>

<script>
import AdvertiserHeaderClickReports from '@/components/reports/advertiser/AdvertiserHeaderClickReports';
import AdvertiserListClickReports from '@/components/reports/advertiser/AdvertiserListClickReports';

export default {
  name: 'CardReportClickAdvertiser',
  components: { AdvertiserHeaderClickReports, AdvertiserListClickReports }
};
</script>
