<template>
  <q-table
    flat
    dense
    :data="data"
    :columns="columns"
    row-key="id"
    responsive
    style="height: calc(100% - 50px) !important; position: relative"
  >
    <template v-slot:body-cell-name="props">
      <q-td :props="props">
        <q-item class="q-pa-xs">
          <q-item-section top avatar>
            <q-avatar color="primary" text-color="white" icon="mdi-account" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Single line item</q-item-label>
            <q-item-label caption lines="2">Secondary line text.</q-item-label>
          </q-item-section>
        </q-item>
      </q-td>
    </template>
  </q-table>
</template>

<script>
import { QSpinnerFacebook } from 'quasar';
export default {
  data() {
    return {
      grid: false,
      selected: [],
      filter: '',
      time: null,
      data: []
    };
  },

  computed: {
    columns() {
      return [
        {
          name: 'channel',
          required: true,
          label: this.$t('disclosure_channel'),
          align: 'left',
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true
        },
        {
          name: 'clicksAll',
          label: this.$t('total_clicks'),
          field: 'clicksAll',
          sortable: true,
          align: 'left'
        },
        {
          name: 'clicks',
          label: this.$t('single_clicks'),
          field: 'clicks',
          sortable: true,
          align: 'left'
        },
        {
          name: 'conversion',
          label: this.$t('pending_conversions'),
          field: 'conversion',
          align: 'left'
        },
        {
          name: 'conversion2',
          label: this.$t('commission'),
          field: 'conversion2',
          align: 'left'
        }
        // {
        //   name: "id",
        //   label: "Ações",
        //   field: "id",
        //   sortable: true,
        // },
      ];
    }
  },

  methods: {
    async getAdvertiser() {
      this.showLoading();
      try {
        const { data } = await this.$http.get('redirect?app=ADVERTISER&path=/api/get/campaign/1');
        console.log(data);
      } catch (error) {
        console.log(error);
      } finally {
        this.timer = setTimeout(() => {
          this.$q.loading.hide();
          this.timer = void 0;
        }, 1000);
      }
    },
    showLoading() {
      this.$q.loading.show({
        spinner: QSpinnerFacebook,
        spinnerColor: 'primary',
        spinnerSize: 140,
        backgroundColor: 'primary',
        message: 'Carregando...',
        messageColor: 'white'
      });
    }
  }
};
</script>
